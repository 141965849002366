<template>
    <div class="fill-height" style="background-color: #212322">
        <v-toolbar elevation="0">
            <v-row align="center">
                <v-col v-if="hasAccount" cols="1" align="center">
                    <v-btn depressed text color="primary" fab small @click="$router.back()">
                        <v-icon>mdi-chevron-left</v-icon>
                    </v-btn>
                </v-col>
                <v-col :cols="hasAccount ? 8 : 9">
                    <v-toolbar-title>SALA VIDEOCONFERENCIA</v-toolbar-title>
                </v-col>
                <v-col cols="3">
                    <v-img :src="require('@/assets/logo-red-black.png')" width="175"/>
                </v-col>
            </v-row>

        </v-toolbar>

        <v-container>
            <v-row align="center" class="my-5">
                <v-col cols="12" v-if="roomId">
                    <jitsi-video-call
                        :user="{displayName: username || 'Invitado AMBSA'}"
                        :room-id="roomId"
                        :height="heightVideoCall"
                        performance-mode
                        :controls="['camera', 'microphone', 'chat', 'desktop', 'fullscreen', 'settings', 'tileview']"
                    />
                </v-col>
                <v-col cols="12" class="background-videochat" v-else>
                    <div class="center-avatar">
                        <v-avatar size="150" tile color="primary" class="white--text" style="font-size: 100px">
                            <b>{{ initialLetter }}</b>
                        </v-avatar>
                        <div class="white--text mt-5" style="font-size: 20px">
                            {{ username }}
                        </div>
                        <div class="white--text">
                            <small>Ha ocurrido un error al encontrar la sala :(</small>
                        </div>
                    </div>
                </v-col>
            </v-row>
        </v-container>

        <v-footer padless fixed color="primary" dark app>
            <v-col class="text-center" cols="12">
                Desde el año 2001 apoyando a las medianas empresas chilenas y extranjeras haciendo negocios en nuestro pais.
            </v-col>
        </v-footer>
    </div>
</template>

<script>
import JitsiVideoCall from "@/components/meeting/JitsiVideoCall";
export default {
    name: "VideochatRoom",
    components: {JitsiVideoCall},
    computed: {
        roomId() {
            return this.$route.params.link || null
        },
        username() {
            return this.$route.query.user || this.account?.name || null
        },
        initialLetter() {
            return this.username?.charAt(0).toUpperCase() || 'n/d'
        },
        heightVideoCall() {
            let height = this.$vuetify.breakpoint.height - 226
            return height < 500 ? 500 : height
        },
        hasAccount() {
            return !!this.account
        }
    }
}
</script>

<style scoped>

.background-videochat {
    position: relative;
    height: calc(100vh - 200px);
    background-color: #212322;
}

.center-avatar {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    text-align: center;
}

</style>