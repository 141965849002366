<template>
    <div ref="jitsi" class="video-container">
        <v-progress-circular
            v-if="loadingVideo"
            indeterminate
            color="white"
            class="video-spinner mx-auto"
            size="60" width="2">
            <v-icon color="white" size="20">mdi-video</v-icon>
        </v-progress-circular>
    </div>
</template>
<script>
export default {
    name: "JitsiVideoCall",
    props: {
        user: {
            type: Object
        },
        roomId: {
            type: String,
            required: true
        },
        height: {
            type: [String, Number],
            default: '500px'
        },
        width: {
            type: String,
            default: '100%'
        },
        performanceMode: {
            type: Boolean,
            default: false
        },

        /*
          Available Controls:
          ---------------------------------------------------------------------------------------
         'microphone', 'camera', 'closedcaptions', 'desktop', 'fullscreen',
         'fodeviceselection', 'hangup', 'profile', 'chat', 'recording',
         'livestreaming', 'etherpad', 'sharedvideo', 'settings', 'raisehand',
         'videoquality', 'filmstrip', 'invite', 'feedback', 'stats', 'shortcuts',
         'tileview', 'videobackgroundblur', 'download', 'help', 'mute-everyone', 'security'
         ---------------------------------------------------------------------------------------
         */

        controls: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            loadingVideo: true,
            api: null,
        }
    },
    mounted() {
        var vm = this
        vm.initialize()
    },
    methods: {
        initialize() {
            const vm = this
            const domain = process.env.VUE_APP_BASE_MEET_URL || 'meet.jit.si'
            const ref = vm.$refs.jitsi

            let options = {
                roomName: vm.roomId,
                width: vm.width,
                height: vm.height,
                configOverwrite: {subject: 'AMBSA'},
                interfaceConfigOverwrite: vm.interfaceOptions,
                parentNode: ref,
                onload: () => {
                    vm.loadingVideo = false
                    vm.$emit('load')
                },
            };


            if (vm.user) {
                options.userInfo = vm.user
            }

            // eslint-disable-next-line no-undef
            vm.api = new JitsiMeetExternalAPI(domain, options);
            vm.api.executeCommand('subject', ' ');
        }
    },
    computed: {
        interfaceOptions() {
            const vm = this

            let defaultOptions = {
                HIDE_INVITE_MORE_HEADER: true,
                VIDEO_QUALITY_LABEL_DISABLED: true,
                SHOW_CHROME_EXTENSION_BANNER: false,
            }

            if (vm.controls.length > 0) {
                defaultOptions.TOOLBAR_BUTTONS = vm.controls
            }

            if (vm.performanceMode) {
                defaultOptions.SET_FILMSTRIP_ENABLED = false
                defaultOptions.DISABLE_FOCUS_INDICATOR = true
                defaultOptions.DISABLE_DOMINANT_SPEAKER_INDICATOR = true
                defaultOptions.DISABLE_VIDEO_BACKGROUND = true
            }

            return defaultOptions

        }
    }
}
</script>

<style scoped>
.video-container {
    background-color: #212322;
    align-items: center;
    justify-content: center;
    display: flex;
}

.video-spinner {
    position: absolute;
}

</style>